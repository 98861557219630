export const ADD_RECIPE = 'ADD_RECIPE';
export const CHANGE_ROUTE = 'CHANGE_ROUTE';
export const FILTER_LIST = 'FILTER_LIST';
export const LOAD_STATE = 'LOAD_STATE';
export const RECEIVE_API_RECIPES = 'RECEIVE_API_RECIPES';
export const SEARCH = 'SEARCH';
export const SEARCH_API_RECIPES = 'SEARCH_API_RECIPES';
export const SELECT_RECIPE = 'SELECT_RECIPE';
export const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_RECIPE_LIST = 'UPDATE_RECIPE_LIST';
