const firebaseConfig = {
  apiKey: 'AIzaSyCxDKkRCalogX4ESXzL69cOMPN7PQqMrsI',
  authDomain: 'half-blood-quince.firebaseapp.com',
  databaseURL: 'https://half-blood-quince.firebaseio.com',
  projectId: 'half-blood-quince',
  storageBucket: 'half-blood-quince.appspot.com',
  messagingSenderId: '608575907404'
};

export default firebaseConfig;
