export const TagList = [
  'Entree',
  'Appetizer',
  'Breakfast',
  'Dessert',
  'Healthy',
  'Quick',
  '60 Minutes',
  'Easy Ingredients',
  'Entertaining',
  'Party Favor',
  'Meal Prep',
  'Instant Pot',
  'Slow Cooker'
];