import React from 'react';
import logo from '../../assets/images/quince.png';

function Logo(){
  return (
    <div>
      <img src={logo} alt="Half-Blood Quince Logo" />
    </div>
  );
}

export default Logo;
